<template>
  <div class="iss-main">
    <a-avatar :size="128">
      <template #icon><UserOutlined /> </template>
    </a-avatar>
    <div class="b-bottom"></div>

    <a-row :gutter="20">
      <a-col :span="12" class="pt-20">
        <span><MailOutlined />：</span>
        <span></span>
      </a-col>
      <a-col :span="12" class="pt-20">
        <div>
          <span><PhoneOutlined />：</span>
          <span></span>
        </div>

        <div class="pt-10">
          <span><CommentOutlined />：</span>
          <span></span>
        </div>
      </a-col>
    </a-row>

    <div class="pt-20 pb-20">
      <span class="mr-5">任务数：{{ 0 }},</span>
      <span class="mr-5">领取任务：{{ 0 }}</span
      >,
      <span class="mr-5">自建任务：{{ 0 }},</span>
      <span class="mr-5">潜客数量：{{ 0 }},</span>
      <span class="mr-5">本日新增潜客：{{ 0 }},</span>
      <span class="mr-5">商机数据：{{ 0 }},</span>
      <span class="mr-5">监测中商机：{{ 0 }},</span>
      <span class="mr-5">已完成商机：{{ 0 }}</span>
    </div>

    <a-tabs
      v-model:activeKey="activeKey"
      :tabBarStyle="{
        margin: '0px',
        marginBottom: '20px',
      }"
    >
      <a-tab-pane key="1" tab="我的任务">
        <H3><b>任务列表</b></H3>
        <search-form
          :items="items"
          :layout="{ sm: 24, md: 12, lg: 8 }"
          :default-value="search"
          @fnSearch="handleFnSearch"
        />
        <div class="m-t">
          <grid
            ref="gridRefs"
            :columns="columns"
            :code="$route.path"
            :url="taskUrl"
            :search="search"
            :btn-operation="btnOperation"
            :scroll="{ x: 1000, y: gridHeight }"
          >
            <template #enabled="{ record }">
              <a-switch
                :checked="record.enabled"
                @click="handleByEnabled(record)"
              />
            </template>

            <template #operation="{ record }">
              <operation :options="options" :record="record" />
            </template>

            <!--      <template #promoteName="{ text }">-->
            <!--        <span>{{ text.promoteName }}</span>-->
            <!--      </template>-->
            <!--      <template #promoteType="{ text }">-->
            <!--        <span>{{ promoteType[text] }}</span>-->
            <!--      </template>-->
          </grid>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="我的商机">
        <div class="m-t">
          <!--     :url-params="{ promoteId: conData.id }"     -->
          <grid
            ref="gridRef"
            :columns="column"
            :code="$route.path"
            :url="url"
            :btn-operation="btnOperation"
            :scroll="{ x: 900, y: gridHeight }"
          >
            <template #posterUri="{ text }">
              <img :src="text.posterUri" width="130" />
            </template>
            <template #operation="{ record }">
              <operation :options="options" :record="record" />
            </template>
          </grid>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { Avatar, Row, Col, Tabs } from 'ant-design-vue';
import {
  UserOutlined,
  CommentOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons-vue';
import { reactive, ref, toRefs } from 'vue';
import iRadarApi from '@/api/iRadar';
import Operation from '@/components/operation';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';

export default {
  components: {
    AAvatar: Avatar,
    ARow: Row,
    ACol: Col,
    UserOutlined,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    Operation,
    SearchForm,
    Grid,
    CommentOutlined,
    MailOutlined,
    PhoneOutlined,
  },

  setup() {
    const gridRef = ref();
    const gridRefs = ref();
    const state = reactive({
      search: { campaignName: '', promoteType: '' },
    });

    const form = reactive({});

    return {
      ...toRefs(state),
      form,
      gridHeight: document.body.clientHeight - 433,
      gridRef,
      gridRefs,
      // 我的商机列表
      url: iRadarApi.pageUrl,
      // 我的任务列表
      taskUrl: iRadarApi.pageUrl,
      items: [
        {
          key: 'prizeStatus',
          label: '任务渠道',
          type: 'select',
          dataset: [
            { value: '0', label: '领取任务' },
            { value: '1', label: '自建任务' },
          ],
        },
      ],
      // 我的任务
      columns: [
        { dataIndex: 'promoteName', title: '任务名称', width: 150 },
        {
          dataIndex: 'promoteType',
          title: '任务渠道',
          slots: { customRender: 'promoteType' },
          width: 100,
        },
        { dataIndex: 'createTime', title: '访问人数', width: 180 },
        { dataIndex: 'createUserName', title: '访问次数', width: 120 },
      ],
      // 我的商机
      column: [
        { dataIndex: 'promoteName', title: '姓名', width: 120 },
        {
          dataIndex: 'promoteType',
          title: '来源',
          slots: { customRender: 'promoteType' },
          width: 100,
        },
        { dataIndex: 'createTime', title: '公司', width: 180 },
        { dataIndex: 'createUserName', title: '首次留资', width: 120 },

        // {
        //   dataIndex: 'id',
        //   fixed: 'right',
        //   title: '操作',
        //   width: 65,
        //   slots: { customRender: 'operation' },
        // },
      ],

      handleFnSearch: value => {
        state.search = value;
      },
    };
  },
};
</script>

<style scoped>
.iss-main {
  max-height: calc(100vh - 134px);
  overflow-y: auto;
}
.m-t {
  padding: 10px 7px;
  border-radius: 8px;
  overflow: hidden;
}
.pt-20 {
  padding-top: 30px;
}
.b-bottom {
  padding-top: 10px;
  border-bottom: 1px solid #f0f0f0;
}
.pb-20 {
  padding-border: 25px;
}
.mr-5 {
  margin-right: 8px;
}
</style>
